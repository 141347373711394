import React, { MouseEvent } from 'react'

import { BreadcrumbJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

import clsx from 'clsx'

import { getBaseUrl } from '@/lib/contentful/utils'

import { addTrailingSlash } from '@/utils/locales/localized-routes/helpers/addTrailingSlash'

import { Link } from '@/components/CustomLink'

import { Warning } from '../Warning'

const VARIANT_STYLES = {
	light: 'text-gray-600 [&_a]:text-bc-blue [&_a:hover]:text-bc-black',
	dark: 'text-white [&_a]:text-white [&_a:hover]:text-bc-green'
}

type Breadcrumb = {
	text?: string | null
	link?: {
		href: string
		onClick?(event: MouseEvent): void
		target?: '_self' | '_blank'
	}
	schemaUrl?: string
	seoOnly?: boolean
	linkOnly?: boolean
}

type Props = {
	className?: string
	breadcrumbs: Breadcrumb[]
	currentPage?: string | null
	variant?: 'light' | 'dark'
	dataTestid?: string
}

export function Breadcrumbs({
	className,
	breadcrumbs,
	currentPage,
	dataTestid = 'breadcrumbs',
	variant = 'light'
}: Props) {
	const router = useRouter()
	const baseUrl = getBaseUrl(router.locale)

	if (breadcrumbs.length === 0 && currentPage?.length === 0) {
		return <Warning className={className}>There are no breadcrumbs</Warning>
	}

	const breadcrumbsSchema = breadcrumbs?.filter((breadcrumb) => !breadcrumb.linkOnly)

	const firstItem = breadcrumbsSchema.at(0)

	const firstItemUrl = firstItem
		? firstItem?.schemaUrl ?? new URL(firstItem?.link?.href ?? '', baseUrl).toString()
		: undefined

	const breadcrumbsIncludeHomepage = stripTrailingSlash(firstItemUrl) === baseUrl

	if (!breadcrumbsIncludeHomepage) {
		breadcrumbsSchema.unshift({
			text: 'Homepage',
			schemaUrl: `${baseUrl}/`,
			seoOnly: true
		})
	}

	return (
		<>
			<BreadcrumbJsonLd
				itemListElements={[
					...breadcrumbsSchema.map((breadcrumb, i) => ({
						position: i + 1,
						name: breadcrumb.text,
						item:
							breadcrumb.schemaUrl ??
							`${new URL(addTrailingSlash(breadcrumb.link?.href ?? ''), baseUrl)}`
					})),
					...(currentPage
						? [
								{
									position: breadcrumbsSchema.length + 1,
									name: currentPage,
									item: new URL(router.asPath, baseUrl).toString()
								}
						  ]
						: [])
				]}
			/>

			<nav aria-label="Breadcrumb" className={className} data-testid={dataTestid}>
				<ul className={clsx(VARIANT_STYLES[variant], 'flex w-full text-xs')}>
					{breadcrumbs?.map((breadcrumb, i) => {
						if (breadcrumb.seoOnly) return null

						return (
							<li
								key={i}
								className={clsx(
									'min-w-0 items-center font-medium flex shrink-auto md:shrink-0 first:shrink-0 [&:first-child>svg]:hidden'
								)}
							>
								<svg className="w-2 h-2 fill-current mx-2 shrink-0" viewBox="0 0 8 15">
									<path d="M5.605 7.943.277 13.322a.988.988 0 0 0 .032 1.403c.398.379 1.03.364 1.411-.032l6.003-6.08a.988.988 0 0 0-.015-1.387l-6.003-6.13a1.003 1.003 0 0 0-1.413 0 .988.988 0 0 0 0 1.404l5.313 5.443Z" />
								</svg>

								<span className="truncate flex-1">
									{breadcrumb.link ? (
										<Link {...breadcrumb.link}>{breadcrumb.text}</Link>
									) : (
										breadcrumb.text
									)}
								</span>
							</li>
						)
					})}
					<li className="items-center hidden md:flex min-w-0 shrink">
						<svg className="w-2 h-2 fill-current mx-2 shrink-0" viewBox="0 0 8 15">
							<path d="M5.605 7.943.277 13.322a.988.988 0 0 0 .032 1.403c.398.379 1.03.364 1.411-.032l6.003-6.08a.988.988 0 0 0-.015-1.387l-6.003-6.13a1.003 1.003 0 0 0-1.413 0 .988.988 0 0 0 0 1.404l5.313 5.443Z" />
						</svg>
						<span className="truncate">{currentPage}</span>
					</li>
				</ul>
			</nav>
		</>
	)
}

const stripTrailingSlash = (string?: string) => {
	return string?.endsWith('/') ? string?.slice(0, -1) : string
}
